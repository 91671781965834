import React, { useState, useMemo, useCallback } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import "./EditArticle.css";
import { HexColorPicker } from "react-colorful";
import { fetchCoordinates } from "../FetchCoordinates/fetchCoordinates";

const libraries = ["places"];

const subcategoryOptions = {
    "news": ["business", "elections", "entertainment", "finance", "financial institution", "general", "health",
             "legal", "science", "sports", "technology", "emergency", "accident", "flight", "terror", "vessel", "explosion"],
    "conflict": ["air defense", "accident/death", "airforce", "flight", "military", "missile", "navy", "navy ship",
                 "terror", "warzone", "health", "explosion", "emergency"]
};

const EditArticle = ({ article, onSave, onClose }) => {
    const [editedArticle, setEditedArticle] = useState({
        title: article.title,
        category: article.category,
        description: article.description,
        location: article.location,
        zone: article.zone || "",
        latitude: article.latitude,
        longitude: article.longitude,
        color: article.color || "#000000"
    });
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [locationAutocomplete, setLocationAutocomplete] = useState(null);
    const [zoneAutocomplete, setZoneAutocomplete] = useState(null);

    const availableSubcategories = useMemo(() => {
        const allSubcategories = [...subcategoryOptions.news, ...subcategoryOptions.conflict];
        return [...new Set(allSubcategories)];
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setEditedArticle(prev => ({ ...prev, [field]: value }));
    }, []);

    const handleSave = useCallback(() => {
        const { title, description, location, category, latitude, longitude, zone, color } = editedArticle;
        onSave(article.id, title, description, location, category, latitude, longitude, zone, color);
        onClose();
    }, [article.id, editedArticle, onSave, onClose]);

    const coordinates = useCallback(async (location) => {
        try {
            const data = await fetchCoordinates(location);
            if (data) {
                setEditedArticle(prev => ({ ...prev, latitude: data.lat, longitude: data.lng }));
            } else {
                console.error("Coordinates fetch failed or returned null");
            }
        } catch (error) {
            console.error("Error fetching coordinates:", error.message);
        }
    }, []);

    const onLocationLoad = useCallback((autocompleteInstance) => {
        setLocationAutocomplete(autocompleteInstance);
    }, []);

    const onLocationPlaceChanged = useCallback(() => {
        if (locationAutocomplete !== null) {
            const place = locationAutocomplete.getPlace();
            if (place.geometry) {
                handleInputChange('location', place.formatted_address);
                coordinates(place.formatted_address);
            } else {
                console.error("No geometry information available for the location.");
            }
        } else {
            console.log("Location autocomplete is not loaded yet!");
        }
    }, [locationAutocomplete, handleInputChange, coordinates]);

    const onZoneLoad = useCallback((autocompleteInstance) => {
        setZoneAutocomplete(autocompleteInstance);
    }, []);

    const onZonePlaceChanged = useCallback(() => {
        if (zoneAutocomplete !== null) {
            const place = zoneAutocomplete.getPlace();
            if (place.formatted_address) {
                handleInputChange('zone', place.formatted_address);
            } else {
                console.error("No zone information available.");
            }
        } else {
            console.log("Zone autocomplete is not loaded yet!");
        }
    }, [zoneAutocomplete, handleInputChange]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCTMoyfhP1PdcrLsPNFQ4YQmyH_q-Yka44", 
        libraries,
    });
    
    const handleColorSelect = useCallback(() => {
        setShowColorPicker(false);
    }, []);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps</div>;
    }

    return (
        <div className="edit-article-modal">
            <div className="edit-article-content">
                <h2>Edit Article</h2>
                <div className="article-info">
                    <small>Headline</small>
                    <textarea 
                        value={editedArticle.title} 
                        onChange={(e) => handleInputChange('title', e.target.value)} 
                    />
                </div>

                <div className="article-info">
                    <small>Tag</small>
                    <select 
                        value={editedArticle.category} 
                        onChange={(e) => handleInputChange('category', e.target.value)}
                    >
                        <option value="">Select a subcategory</option>
                        {availableSubcategories.map((subcat) => (
                            <option key={subcat} value={subcat}>{subcat}</option>
                        ))}
                    </select>
                </div>

                <div className="article-info">
                    <small>Description</small>
                    <textarea 
                        value={editedArticle.description} 
                        onChange={(e) => handleInputChange('description', e.target.value)} 
                    />
                </div>

                <div className="article-info autocomplete">
                    <small>Location</small>
                    <Autocomplete onLoad={onLocationLoad} onPlaceChanged={onLocationPlaceChanged}>
                        <input 
                            value={editedArticle.location} 
                            onChange={(e) => handleInputChange('location', e.target.value)} 
                            placeholder="Enter location" 
                        />
                    </Autocomplete>
                </div>

                <div className="article-coordinates">
                    <div className="lat-lng">
                        <div className="article-coordinate">
                            <small>Latitude</small>
                            <input value={editedArticle.latitude} disabled />
                        </div>
                        <div className="article-coordinate">
                            <small>Longitude</small>
                            <input value={editedArticle.longitude} disabled />
                        </div>
                    </div>
                </div>
                
                <div className="article-info autocomplete">
                    <small style={{"marginTop":"10px"}}>Zone</small>
                    <Autocomplete onLoad={onZoneLoad} onPlaceChanged={onZonePlaceChanged}>
                        <input 
                            value={editedArticle.zone} 
                            onChange={(e) => handleInputChange('zone', e.target.value)} 
                            placeholder="Enter zone (e.g., district or city)" 
                        />
                    </Autocomplete>
                </div>

                <div className="article-coordinates ">
                    <div className="lat-lng">
                        <div className="article-coordinate">
                            <small>Color</small>
                            <div className="color-picker-container">
                                <button type="button" onClick={() => setShowColorPicker(!showColorPicker)} className="select-color-button">
                                    <span className="color-name">{editedArticle.color}</span>
                                    <div className="color-display" style={{ backgroundColor: editedArticle.color }}></div>
                                    {showColorPicker && (
                                    <div className="color-picker-overlay">
                                    <HexColorPicker 
                                        color={editedArticle.color} 
                                        onChange={(color) => handleInputChange('color', color)} 
                                    />
                                    <button className="ok-button" onClick={handleColorSelect}>OK</button>
                                    </div>
                                    )}
                                </button>
                            </div>
                        </div>
                        
                        <div className="edit-article-buttons">
                            <button onClick={onClose} className="cancel-button">Close</button>
                            <button onClick={handleSave} className="update-button">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditArticle;