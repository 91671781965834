import React, { useState } from "react";
import axios from "axios";
import "./AddNews.css";
import { HexColorPicker } from "react-colorful";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];
const API_BASE_URL = "https://api.mapmynews.com/public/index.php";
const categoryOptions = ["news","conflict"];
const subcategoryOptions = {"news":["business","elections","entertainment","finance","financial institution","general","health",
                            "legal", "science","sports","technology","emergency","accident","flight","terror","vessel", "explosion"],
                            "conflict":["air defense","accident/death","airforce","flight","military","missile","navy","navy ship",
                            "terror","warzone","health","explosion","emergency"]};

const AddNews = ({ onClose, onSuccess }) => {
  const [image, setImage] = useState(null);
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [source, setSource] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory,setSubcategory] = useState("")
  const [publishedAt, setPublishedAt] = useState("");
  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("#000000"); 
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [autocompleteLocation, setAutocompleteLocation] = useState(null);
  const [autocompleteZone, setAutocompleteZone] = useState(null);
  const [fileName, setFileName] = useState("");
  const [zone, setZone] = useState(null);
  const [currentSubcategories, setCurrentSubcategories] = useState([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCTMoyfhP1PdcrLsPNFQ4YQmyH_q-Yka44",
    libraries,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);
    formData.append("author", author);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("url", url);
    formData.append("source", source);
    formData.append("category", category);
    formData.append("publishedAt", publishedAt);
    formData.append("location", location);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("zone", zone);
    formData.append("color", color);
    formData.append("subcategory",subcategory);

    try {
      const response = await axios.post(API_BASE_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message);
      console.log(response.data.message);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error uploading article:", error);
      setMessage("Error uploading article: " + (error.response?.data?.message || error.message));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setFileName(file ? file.name : "");
  };

  const onLocationLoad = (autocompleteInstance) => {
    setAutocompleteLocation(autocompleteInstance);
  };

  const onLocationPlaceChanged = () => {
    if (autocompleteLocation !== null) {
      const place = autocompleteLocation.getPlace();
      if (place.geometry) {
        setLocation(place.formatted_address);
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
      } else {
        console.error("No geometry information available for the location.");
      }
    } else {
      console.log("Location autocomplete is not loaded yet!");
    }
  };

  const onZoneLoad = (autocompleteInstance) => {
    setAutocompleteZone(autocompleteInstance);
  };

  const onZonePlaceChanged = () => {
    if (autocompleteZone !== null) {
      const place = autocompleteZone.getPlace();
      if (place.formatted_address) {
        setZone(place.formatted_address);
      } else {
        console.error("No zone information available.");
      }
    } else {
      console.log("Zone autocomplete is not loaded yet!");
    }
  };

  const handleColorSelect = () => {
    setShowColorPicker(false); 
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setSubcategory(''); 
    setCurrentSubcategories(subcategoryOptions[selectedCategory] || []);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className="add-news-modal">
      <div className="add-news-content">
        <h2>Add News</h2>
        <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
          <div className="add-news-fields">
            <div className="add-news-field">
              <label>Title*</label>
              <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </div>
            <div className="add-news-field">
              <label>Author*</label>
              <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} required />
            </div>
          </div>

          <div className="add-news-fields">
            <div className="add-news-field">
              <label>URL*</label>
              <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} required />
            </div>
            <div className="add-news-field">
              <label>Source*</label>
              <input type="text" value={source} onChange={(e) => setSource(e.target.value)} required />
            </div>
          </div>

          <div className="add-news-fields">
            <div className="add-news-field">
              <label>Category*</label>
              <select value={category} onChange={handleCategoryChange} required>
                <option value="">Select a category</option>
                {categoryOptions.map((option) => (
                  <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            <div className="add-news-field">
              <label>Subcategory*</label>
              <select value={subcategory} 
                onChange={(e) => setSubcategory(e.target.value)} 
                required 
                disabled={!currentSubcategories.length}
              >
                <option value="">Select a subcategory</option>
                {currentSubcategories.map((option) => (
                  <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="add-news-fields">
            <div className="add-news-field file-input-wrapper">
              <label>Add Image*</label>
              <input id="file-upload" type="file" onChange={handleImageChange} required accept="image/*" />
              <label htmlFor="file-upload" className="file-input-label">
                <span className="file-input-text">{fileName}</span>
                <span className="file-input-button">Add Image</span>
              </label>
            </div>
            
            <div className="add-news-field full-width">
                <label>Description*</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} required rows="1"></textarea>
            </div>
            
          </div>

          <div className="add-news-fields">
            <div className="add-news-field">
              <label>Location*</label>
              <Autocomplete onLoad={onLocationLoad} onPlaceChanged={onLocationPlaceChanged}  className="autocomplete">
                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
              </Autocomplete>
            </div>
            <div className="add-news-field">
              <label>Zone*</label>
              <Autocomplete onLoad={onZoneLoad} onPlaceChanged={onZonePlaceChanged} className="autocomplete">
                <input type="text" value={zone} onChange={(e) => setZone(e.target.value)} required onKeyPress={handleKeyPress} placeholder="Enter zone (e.g., district or city)" />
              </Autocomplete>
            </div>
        </div>

        <div className="add-news-fields">
          <div className="add-news-field">
            <label>Color*</label>
            <div className="color-picker-container">
              <button type="button" onClick={() => setShowColorPicker(!showColorPicker)} className="select-color-button">
                <span className="color-name">{color}</span>
                <div className="color-display" style={{ backgroundColor: color }}></div>
                {showColorPicker && (
                <div className="color-picker-overlay">
                  <HexColorPicker color={color} onChange={setColor} />
                  <button className="ok-button" onClick={handleColorSelect}>OK</button>
                </div>
                )}
              </button>
            </div>
          </div>
          <div className="add-news-field">
            <label>Published At*</label>
            <input type="text" placeholder="yyyy-mm-dd hh:mm:ss" value={publishedAt} onChange={(e) => setPublishedAt(e.target.value)} required />
          </div>
        </div>


        <div className="buttons">
          <button type="submit" className="submit">Submit</button>
          <button type="button" className="cancel" onClick={onClose}>Close</button>
        </div>
          {message && <div className="message">{message}</div>}
        </form>
      </div>
    </div>
  );
};

export default AddNews;
