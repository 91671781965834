import { useNavigate } from 'react-router-dom';
import React from "react";
import axios from "axios";
import "./Login.css"
const Login = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(""); 

        axios.post('https://mapmynews.com/api/login/login.php', { username, password })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    const { token, username } = response.data;
                    localStorage.setItem('userToken', token);
                    localStorage.setItem('username', username);
                    navigate('/home');
                } else {
                    setErrorMessage(response.data.message);
                }
            })
            .catch(error => {
                console.error(error);
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("An error occurred. Please try again.");
                }
            });
    };

    return (
        <div className="login-page">
            <img src="mmn4 1.png" />
            <form onSubmit={handleSubmit} className='login-form'>
                {errorMessage && <p style={{ color: 'red', textAlign:'center', width:'100%' }}>{errorMessage}</p>}            
                <div className='input'>
                    <p>Username</p><br/>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="username" required /> <br />
                </div>
                <div className='input'>
                    <p>Password</p><br/>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" required /><br />
                </div>
                <button type="submit" className='button'>Login</button>
            </form>
            <small className='credits'><strong>© MapmyNews | 2024</strong></small>
        </div>
    );
};

export default Login;
