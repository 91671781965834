import React from "react";
import "./ConfirmDelete.css";

const ConfirmDelete = ({ article, onCancel, onConfirm }) => {
    const handleCancel = () => {
        onCancel();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <div className="confirm-delete-modal">
            <div className="confirm-delete-content">
                <h2>Delete Article</h2><br/>
                <p>Are you sure you want to delete the article {article.id}?</p>
                <div className="confirm-delete-buttons">
                <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
                <button className="confirm-btn" onClick={handleConfirm}>Delete</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDelete;
