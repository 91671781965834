import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import "./Logout.css"
const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user data (this depends on how you store user data)
        localStorage.removeItem('userToken');
        localStorage.removeItem('username');
        
        // Redirect to login page
        navigate('/');
    };

    return (
        <div className="logout-button" onClick={handleLogout}>
            <button>Logout</button>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
        </div>
        
    );
};

export default Logout;
