export const fetchCoordinates = async (placeName) => {
    const apiKey = "AIzaSyCTMoyfhP1PdcrLsPNFQ4YQmyH_q-Yka44"; 
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(placeName)}&key=${apiKey}`
    );
    const data = await response.json();

    if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        return {
            lat: location.lat,
            lng: location.lng,
        };
    } else {
        throw new Error("Unable to fetch coordinates");
    }
};
